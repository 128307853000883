<h1 mat-dialog-title>
    {{ data.name }}
</h1>

<div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="1vh" fxLayoutAlign="center center">
        <div fxFlex>
            <img class="profile" [src]="data.pictureLink" />
        </div>
        <div fxFlex>
            <span class="label">{{investigatorsMeta.displayName}}: </span>
            <span *ngFor="let itm of data.investigators;let index = index;">{{itm.name}}<span
                    *ngIf="index < data.investigators.length - 1">, </span></span>
        </div>
        <!-- <div fxFlex>
            <span class="label">{{badgeNoMeta.displayName}}:</span> {{data.badgeNo || '-'}}
        </div>
        <div fxFlex>
            <span class="label">{{rankMeta.displayName}}:</span> {{(data.rank | enum: rankEnum) || '-'}}
        </div>
        <div fxFlex>
            <span class="label">{{divisionMeta.displayName}}:</span> {{(data.division | enum: divisionEnum)
            || '-'}}
        </div> -->
    </div>
</div>

<div mat-dialog-actions align="end">
    <!-- <button mat-raised-button (click)="viewProfile()"
        *ngIf="!isInvestigator && !rolesCannotView.includes(data.role._id)">
        {{ "View Profile" | translate }}
    </button> -->
    <button mat-raised-button color="danger" mat-dialog-close>
        {{ "Close" | translate }}
    </button>
</div>