import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MapInfoWindow, GoogleMap } from '@angular/google-maps';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { ref, onValue, off } from 'firebase/database';
import { RequestService } from '../../services';

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapsComponent implements OnInit {
    display: any;
    center: google.maps.LatLngLiteral = {
        lat: 0,
        lng: 0
    };
    zoom = 15;
    options = {
        streetViewControl: false,
        mapTypeControl: true,
        fullscreenControl: false,
        scaleControl: true,
        minZoom: 3,
        disableDoubleClickZoom: false,
        panControl: true,
        draggable: true,
        clickableIcons: false,
        styles: []
    }
    markersOriginal: any[] = [];
    markersOriginalAdjusted: any[] = [];
    markers: any[] = [];
    usersMarkers: any[] = [];
    marker: any = {};
    markerClustererImagePath: string = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
    showCluster: boolean = false;
    geoLocationInit: boolean = false;
    geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    defaultMarkerIcon: string = undefined;
    polylines: Map<string, google.maps.Polyline> = new Map();
    polylinesMain: google.maps.LatLngLiteral[][] = []
    polylinePath: google.maps.LatLngLiteral[] = [];
    polylineOptions: google.maps.PolylineOptions = {
        path: this.polylinePath,
        visible: true
    };
    observer: IntersectionObserver;
    @Input() lazyLoad: boolean = false;

    public selectedMarker = 'Info Window content';

    private lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
    };

    @Input()
    set styles(styles: any) {
        // if (styles?.length) {
        // debugger;
        if (this.markersElem) {
            this.options.styles = styles;
            this.markersElem.googleMap.setOptions(this.options);
        }
        // }
    }
    @Input() currentGeoLocation: any = undefined;
    @Input() width: string = '100%';
    @Input() height: string = '30vh';
    @Input() enableTrackMarkers: boolean = true;
    @Input() addMyMarker: boolean = true;
    @Input() isClickable: boolean = true;
    @Input() mapDragGeoEnabled: boolean = false;
    @Input() isHoverable: boolean = false;
    @Input()
    set isDraggable(isDraggable: boolean) {
        this.options.draggable = isDraggable;
    }
    @Input()
    set setGeoLocation(geoLocation: string) {
        if (geoLocation && geoLocation !== '') {
            this.currentGeoLocation = JSON.parse(geoLocation);
            if (!this.geoLocationInit) {
                this.center = this.currentGeoLocation;
                this.geoLocationInit = true;
            }
            this.setPin(this.currentGeoLocation);
        } else {
            this.currentGeoLocation = undefined;
        }
    }
    @Input()
    set setCenter(geoLocation: string) {
        if (geoLocation && geoLocation !== '') {
            this.currentGeoLocation = JSON.parse(geoLocation);
            if (!this.geoLocationInit) {
                this.center = this.currentGeoLocation;
                this.geoLocationInit = true;
            }
        } else {
            this.currentGeoLocation = undefined;
        }
    }
    @Input()
    set changeCenter(geoLocation: string) {
        if (geoLocation && geoLocation !== '') {
            this.currentGeoLocation = JSON.parse(geoLocation);
            this.center = this.currentGeoLocation;
        }
    }
    @Input()
    set changeCenterAddPin(geoLocation: string) {
        if (geoLocation && geoLocation !== '') {
            this.currentGeoLocation = JSON.parse(geoLocation);
            this.center = this.currentGeoLocation;
            this.setPin(this.currentGeoLocation);
        }
    }
    @Input()
    set markerIcon(icon: string) {
        if (icon) {
            this.defaultMarkerIcon = icon;
            if (this.currentGeoLocation)
                this.setPin(this.currentGeoLocation);
        }
    }
    @Input()
    set setMapTypeControl(mapTypeControl: boolean) {
        this.options.mapTypeControl = mapTypeControl;
    }
    @Input()
    set setScaleControl(scaleControl: boolean) {
        this.options.scaleControl = scaleControl;
    }

    @Output() address = new EventEmitter<string>();
    @Output() geoLocation = new EventEmitter<any>();
    @Output() markerSelect = new EventEmitter<any>();
    @Output() markerInfoSelect = new EventEmitter<any>();
    @Output() mapSelect = new EventEmitter<any>();
    @Output() mapInitialized = new EventEmitter<any>();
    @Output() tilesloaded = new EventEmitter<any>();
    @Output() addressPinData = new EventEmitter<any>();
    @Output() addressJPPinData = new EventEmitter<any>();
    @Output() mapDragGeo = new EventEmitter<any>();

    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
    @ViewChild(GoogleMap) markersElem: GoogleMap;
    constructor(private translate: TranslateService, private firebaseService: FirebaseHandlersService, private elementRef: ElementRef, private requestService: RequestService) {
    }

    ngOnInit(): void {
        if (this.currentGeoLocation) {
            this.center = this.currentGeoLocation;
        }
        else if (navigator.geolocation) {
            try {
                navigator.geolocation.getCurrentPosition((position: any) => {
                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                    this.geocodeLatLng(this.geocoder, position.coords.latitude, position.coords.longitude);
                    if (this.addMyMarker) {
                        this.marker = {
                            position: this.center,
                            // label: {
                            //     color: 'blue',
                            //     text: 'Marker label ',
                            // },
                            title: this.translate.instant('My Pin'),
                            info: 'My Marker info',
                            options: {
                                animation: google.maps.Animation.DROP,
                            },
                        }

                        if (this.defaultMarkerIcon) {
                            this.marker.icon = this.defaultMarkerIcon;
                        }

                        if (this.markersElem && this.mapDragGeoEnabled) {
                            this.mapDragGeo.emit(this.markersElem.getCenter().toJSON());
                        }
                    }
                }, (error: any) => {
                    this.makeNoLocationReady();
                });
            }
            catch (e) {
                this.makeNoLocationReady();
            }
        } else {
            this.makeNoLocationReady();
        }
    }
    makeNoLocationReady() {
        let lng = 138.2529;
        let lat = 36.2048;
        this.center = {
            lat: lat,
            lng: lng
        }
        this.zoom = 4;
        this.geocodeLatLng(this.geocoder, lat, lng);

    }
    ngOnDestroy() {
        this.unTrackMarkers(['investigator', 'investigator-group']);
    }
    ngAfterViewInit() {
        if (this.markersElem && this.mapDragGeoEnabled) {
            this.mapDragGeo.emit(this.markersElem.getCenter().toJSON());
        }
        if (this.lazyLoad) {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            this.lazyLoad = false;
                        }, 300)
                        this.observer.disconnect();
                    }
                });
            }, {
                threshold: 0.1 // Adjust this threshold as needed
            });

            this.observer.observe(this.elementRef.nativeElement);
        }

    }

    // moveMap(event: google.maps.MapMouseEvent) {
    //     if (event.latLng != null) this.center = (event.latLng.toJSON());
    // }

    // move(event: google.maps.MapMouseEvent) {
    //     if (event.latLng != null) this.display = event.latLng.toJSON();
    // }
    tilesload(event: any) {
        this.tilesloaded.emit(event);
    }
    mapInitialize(event: any) {
        this.mapInitialized.emit(event);
    }
    mapClick(event: any) {
        if (this.isClickable) {
            let lat = event.latLng.lat();
            let lng = event.latLng.lng();
            this.reverseGeocode(lat, lng);
            this.mapSelect.emit({ lat: lat, lng: lng });
        }
    }
    mapDrag(event: any) {
        if (this.markersElem && this.mapDragGeoEnabled) {
            this.mapDragGeo.emit(this.markersElem.getCenter().toJSON());
        }
    }
    onCenterChange(event: google.maps.MapMouseEvent) {
        // this.center = event.latLng.toJSON();
        console.log('Map center:', event.latLng.toJSON());
        // You can perform any additional actions here with the new center coordinates
    }
    markerHover(event: any, mark: any, marker) {
        if (this.isHoverable) {
            this.selectedMarker = mark;
            if (!mark.hideInfo) {
                this.infoWindow.open(marker);
            }
        }
    }
    markerHoverOut(event: any) {
        if (this.isHoverable) {
            this.infoWindow.close();
        }
    }
    markerClick(event: any, mark: any, marker) {
        if (this.isClickable) {
            this.markerSelect.emit(mark);
            this.selectedMarker = mark;
            if (!mark.hideInfo) {
                this.infoWindow.open(marker);
            }
        }
    }
    selectMarkerInfo() {
        if (this.isClickable) {
            this.markerInfoSelect.emit(this.selectedMarker);
        }
    }
    setPin(event: any) {
        if (this.isClickable) {
            // event.latLng.lat()
            // event.latLng.lng()
            this.geocodeLatLng(this.geocoder, event.lat, event.lng);
            this.marker = {
                position: {
                    lat: event.lat,
                    lng: event.lng,
                },
                // label: {
                //   color: 'blue',
                //   text: 'Marker label ' + (this.markers.length + 1),
                // },
                title: this.translate.instant('Pin'),
                info: 'Marker info',
                hideInfo: true,
                // options: {
                //     animation: google.maps.Animation.DROP,
                // },
            }

            if (this.defaultMarkerIcon) {
                this.marker.icon = this.defaultMarkerIcon;
            }
        }
    }

    geocodeLatLng(
        geocoder: google.maps.Geocoder, lat: any, lng: any
    ) {
        const latlng = {
            lat: lat,
            lng: lng,
        };

        geocoder
            .geocode({ location: latlng })
            .then((response) => {
                if (response.results[0]) {
                    // map.setZoom(11);

                    // const marker = new google.maps.Marker({
                    //     position: latlng,
                    //     map: map,
                    // });
                    this.address.emit(response.results[0].formatted_address);
                    this.geoLocation.emit(latlng);
                    // console.log(response.results[0].formatted_address)
                    // infowindow.setContent(response.results[0].formatted_address);
                    // infowindow.open(map, marker);
                } else {
                    // window.alert("No results found");
                }
            })
            .catch((e) => {
                // window.alert("Geocoder failed due to: " + e)
            });
    }
    getHideInfo(mark) {
        let status = false;
        if (mark.hideInfo) {
            return true;
        }
        // || mark.markerType === 'note'
        if (mark.markerType === 'document') {
            let description = mark.description ? mark.description : mark.notes ? mark.notes : ''
            if (description === '')
                return true;
        } else if (mark.markerType === 'location') {
            return true;
        }
        return status;
    }
    prepareMarker(mark) {
        let marker = {
            position: {
                lat: mark.lat,
                lng: mark.lng,
            },
            geolocation: mark.geolocation,
            email: mark.email,
            lat: mark.lat,
            lng: mark.lng,
            delete: mark.delete,
            // label: {
            //     color: 'blue',
            //     text: 'Marker label ' + (this.markersOriginal.length + 1),
            // },
            dataType: mark.type ? mark.type : mark.dataType,
            investigators: mark.investigators ? mark.investigators : undefined,
            investigatorgroup: mark.investigatorgroup ? mark.investigatorgroup : undefined,
            cameras: mark.cameras ? mark.cameras : undefined,
            // type: mark.type,
            markerType: mark.markerType,
            _id: mark._id,
            title: mark.name,
            // label: mark.name,
            hideInfo: this.getHideInfo(mark),
            label: this.prepareLabel(mark),
            name: mark['alternative_name'] ? mark['alternative_name'] : mark['name'],
            camera_id: mark.camera_id,
            case_id: mark.case_id,
            case: mark.case,
            status: mark.status,
            pictureLink: mark.pictureLink ? mark.pictureLink : mark.picture ? mark.picture : 'assets/images/error-default-image.png',
            picture: mark.picture ? mark.picture : mark.pictureLink ? mark.pictureLink : 'assets/images/error-default-image.png',
            picture_icon: mark.picture_icon ? mark.picture_icon : mark.picture ? mark.picture : mark.pictureLink ? mark.pictureLink : 'assets/images/error-default-image.png',
            icon: mark.icon ? mark.icon : '',
            description: mark.description ? mark.description : mark.notes ? mark.notes : '',
            rank: mark.rank ? mark.rank : '',
            years_in_service: mark.years_in_service ? mark.years_in_service : '',
            info: 'Marker info ' + (this.markersOriginal.length + 1),
            // options: {
            //     animation: google.maps.Animation.DROP,
            // },
            createdAt: mark.createdAt,
            note: mark,
        };
        // if (mark['icon']) {
        //     marker['icon'] = mark['icon'];
        // }

        if (mark.icon) {
            marker['options'] = {
                icon: {
                    url: mark.icon,
                    // scaledSize: new google.maps.Size(40, 40)
                }
            };
        }
        // for (let item of this.markersOriginal) {
        //     if (this.markersCoincide(item, marker)) {
        //         marker = this.moveMarkerRandomly(marker);
        //         break;
        //     }
        // }
        return marker;
    }
    prepareLabel(mark) {
        let label = {};
        if (typeof mark.label === "object") {
            label = mark.label;
        } else {
            label = mark.label ? {
                text: this.transform(mark.label, ['50', '...']),
                color: mark.labelColor ? mark.labelColor : 'black',
                fontSize: '12px',
                fontWeight: 'bold',
                className: 'titleMarkClass'
            } : mark.name ? {
                text: this.transform(mark.name, ['50', '...']),
                color: mark.labelColor ? mark.labelColor : 'black',
                fontSize: '12px',
                fontWeight: 'bold',
                className: 'titleMarkClass'
            } : mark.case_id ? {
                text: this.transform(mark.case_id, ['50', '...']),
                color: mark.labelColor ? mark.labelColor : 'black',
                fontSize: '12px',
                fontWeight: 'bold',
                className: 'titleMarkClass'
            } : undefined;
        }

        return label;
    }
    moveMarkerRandomly(mark) {
        const dx = Math.random() * 0.0001; // Adjust the range as needed
        const dy = Math.random() * 0.0001;
        const newLat = mark.position.lat + dx;
        const newLng = mark.position.lng + dy;
        mark.position.lat = newLat;
        mark.position.lng = newLng;
        return mark;
    }
    markersCoincide(marker1, marker2) {
        const mark1 = new google.maps.LatLng(marker1.position.lat, marker1.position.lng);
        const mark2 = new google.maps.LatLng(marker2.position.lat, marker2.position.lng);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(mark1, mark2);
        return (marker1.position.lat === marker2.position.lat && marker1.position.lng === marker2.position.lng) || distance < 4;
    }
    addMarkers(markers) {
        this.markersOriginal = [];
        // console.log('markers', markers);
        for (let mark of markers) {
            // if (mark.lat && mark.lat !== '' && mark.lng && mark.lng !== '') {
            let marker = this.prepareMarker(mark);
            this.markersOriginal.push(marker);
            // }
        }
        // console.log('markersOriginal', this.markersOriginal)
        this.showMarkers();
    }
    updateMarkers(newMarkers = []) {
        // console.log('markers', markers);
        // let markersOriginal = JSON.parse(JSON.stringify(this.markersOriginal));
        // console.log('this.markersOriginal', markersOriginal);
        for (let mark of newMarkers) {
            // if (mark.lat && mark.lat !== '' && mark.lng && mark.lng !== '') {
            let marker = this.prepareMarker(mark);
            this.updateOriginalMarker(marker);
            // }
        }
        // markersOriginal = JSON.parse(JSON.stringify(this.markersOriginal));
        // console.log('this.markersOriginal', markersOriginal);
        this.showMarkers();
    }
    updateOriginalMarker(marker) {
        if (marker.delete) {
            this.markersOriginal = this.markersOriginal.filter(mark => (mark._id !== marker._id) || (mark._id === marker._id && !marker.delete)); // removing to marker delete = true
        } else {
            let isFound = false;
            this.markersOriginal = this.markersOriginal.map((mark) => {
                if (mark._id === marker._id) {
                    mark = marker;
                    isFound = true;
                }
                return mark;
            });
            if (!isFound) {
                this.markersOriginal.push(marker);
            }
        }
    }
    transform(value: string, args: string[]): string {
        let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
        let trail = args.length > 1 ? args[1] : '...';
        return value.length > limit ? value.substring(0, limit) + trail : value;
    }
    showMarkers(typeToHide: string[] = [], calculateCenterZoom: boolean = true) {
        this.adjustPoints();
        this.unTrackMarkers(['investigator', 'investigator-group']);
        this.markers = [];
        // this.usersMarkers = [];
        let markersOriginal = JSON.parse(JSON.stringify(this.markersOriginalAdjusted));
        if (typeToHide.length > 0) {
            markersOriginal = markersOriginal.filter((itm) => !typeToHide.includes(itm.markerType));
        }
        this.markers = markersOriginal;
        let markersToCalculate = JSON.parse(JSON.stringify(markersOriginal));
        markersToCalculate = markersToCalculate.filter((mark) => mark.lat && mark.lat !== '' && mark.lng && mark.lng !== '');
        if (calculateCenterZoom && markersToCalculate.length > 0) {
            this.calculateCenter(markersToCalculate);
            this.calculateZoomLevel(markersToCalculate);
        }
        this.trackMarkers(['investigator', 'investigator-group']);
    }

    trackMarkers(markerTypes = []) {
        if (this.enableTrackMarkers) {
            if (markerTypes.length > 0) {
                if (this.firebaseService.firebaseRealDB) {
                    this.markers.forEach(mark => {
                        if (markerTypes.includes(mark.markerType)) {
                            let positionPath = 'users';
                            if (mark.markerType === 'investigator-group') {
                                positionPath = 'users-group'
                            }
                            const positionRef = ref(this.firebaseService.firebaseRealDB, positionPath + `/${mark._id}`);
                            onValue(positionRef, (snapshot) => {
                                const mainData = snapshot.val();
                                if (mainData && mainData.hasOwnProperty('position')) {
                                    const positionData = mainData.position;
                                    if (mark.markerType === 'investigator-group') {
                                        let userLogged = mainData.user;
                                        mark['loggedInUser'] = userLogged;
                                        this.hideMark(userLogged);
                                        // console.log(mark.name, mark.markerType, mark._id, '|', userLogged);
                                    } else if (mark.markerType === 'investigator') {
                                        this.hideMarkGroup(mark._id);
                                        // console.log(mark.name, mark.markerType, mark._id);
                                    }
                                    // console.log(`Position data for ${mark._id}:`, positionData);
                                    // console.log(mark._id, mark.position);
                                    if (positionData && positionData.lat && positionData.lat !== '' && positionData.lng && positionData.lng !== '') {
                                        mark.position = positionData;
                                        mark.geolocation = JSON.stringify(positionData);
                                        mark.lat = positionData.lat;
                                        mark.lng = positionData.lng;
                                    }
                                    mark.hideIt = false;
                                }
                                // this.centerUpdatedMarkers()
                                // console.log(mark._id, mark.position);

                            }, (error) => {
                                console.error("Error reading data:", error);
                            });
                        }
                    });
                }
            }
        }
    }
    hideMark(markId) {
        this.markers.map((mark) => {
            if (mark.markerType === 'investigator' && mark._id == markId) {
                mark.hideIt = true
            }
        });
    }
    hideMarkGroup(markId) {
        this.markers.map((mark) => {
            if (mark.markerType === 'investigator-group' && mark['loggedInUser'] && mark['loggedInUser'] == markId) {
                mark.hideIt = true
            }
        });
    }
    centerUpdatedMarkers() {
        let markersToCalculate = JSON.parse(JSON.stringify(this.markers));
        markersToCalculate = markersToCalculate.filter((mark) => mark.lat && mark.lat !== '' && mark.lng && mark.lng !== '');
        if (markersToCalculate.length > 0) {
            this.calculateCenter(markersToCalculate);
            this.calculateZoomLevel(markersToCalculate);
        }
    }
    unTrackMarkers(markerTypes = []) {
        if (this.enableTrackMarkers) {
            if (markerTypes.length > 0) {
                if (this.firebaseService.firebaseRealDB) {
                    this.markers.forEach(mark => {
                        if (markerTypes.includes(mark.markerType)) {
                            let positionPath = 'users';
                            if (mark.markerType === 'investigator-group') {
                                positionPath = 'users-group'
                            }
                            const positionRef = ref(this.firebaseService.firebaseRealDB, positionPath + `/${mark._id}/position`);
                            off(positionRef);
                        }
                    });
                }
            }
        }
    }
    calculateCenter(markersToCalculate) {
        if (markersToCalculate.length > 0) {
            const latSum = markersToCalculate.reduce((sum, marker) => sum + marker.position.lat, 0);
            const lngSum = markersToCalculate.reduce((sum, marker) => sum + marker.position.lng, 0);
            let centerLat = latSum / markersToCalculate.length;
            let centerLng = lngSum / markersToCalculate.length;
            this.center = {
                lat: centerLat,
                lng: centerLng
            }
            this.mapDragGeo.emit(this.center);
        }
    }
    calculateZoomLevel(markersToCalculate) {
        var bounds = new google.maps.LatLngBounds();
        for (var i in markersToCalculate) { // your marker list here
            if (markersToCalculate[i].position && markersToCalculate[i].position.hasOwnProperty('lat') && markersToCalculate[i].position.hasOwnProperty('lng')) {
                bounds.extend(markersToCalculate[i].position) // your marker position, must be a LatLng instance
            }
        }
        if (this.markersElem) {
            this.markersElem.fitBounds(bounds, 75);
        }
    }
    reverseGeocode(lat: number, lng: number) {
        // let language = 'en';
        // if (localStorage.getItem('languageSelected')) {
        //     language = localStorage.getItem('languageSelected');
        // }
        const geocoder = new google.maps.Geocoder();
        const latLng = new google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: latLng, language: this.requestService.lang }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].formatted_address;
                    this.addressPinData.emit(address);
                    //   console.log('Address:', address);
                    // You can now use the address data as needed.
                } else {
                    console.error('No address found');
                }
            } else {
                console.error('Geocoder failed due to:', status);
            }
        });
        geocoder.geocode({ location: latLng, language: this.requestService.lang }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].formatted_address;
                    this.addressJPPinData.emit(address);
                    //   console.log('Address:', address);
                    // You can now use the address data as needed.
                } else {
                    console.error('No address found');
                }
            } else {
                console.error('Geocoder failed due to:', status);
            }
        });
    }
    adjustPoints(): void {
        this.markersOriginalAdjusted = [];
        const minDistance = 4;
        for (let i = 0; i < this.markersOriginal.length; i++) {
            if (this.markersOriginal[i].lat && this.markersOriginal[i].lat !== '' && this.markersOriginal[i].lng && this.markersOriginal[i].lng !== '') {
                let adjusted = false;
                do {
                    adjusted = false;
                    for (let j = 0; j < this.markersOriginalAdjusted.length; j++) {
                        const mark1 = new google.maps.LatLng(this.markersOriginal[i].position.lat, this.markersOriginal[i].position.lng);
                        const mark2 = new google.maps.LatLng(this.markersOriginalAdjusted[j].position.lat, this.markersOriginalAdjusted[j].position.lng);
                        const distance = google.maps.geometry.spherical.computeDistanceBetween(mark1, mark2);
                        if (distance < minDistance) {
                            adjusted = true;
                            this.markersOriginal[i] = this.moveMarkerRandomly(this.markersOriginal[i]);
                            break; // Exit the inner loop to recheck all points from the beginning
                        }
                    }
                } while (adjusted);
            }
            this.markersOriginalAdjusted.push(this.markersOriginal[i]);
        }
    }
    togglePath(id: string, visible: boolean = true) {
        const polyline = this.polylines.get(id);
        if (polyline) {
            polyline.setVisible(visible);
        }
    }
    // removePath(id: string) {
    //     const polyline = this.polylines.get(id);
    //     if (polyline) {
    //         polyline.setMap(null);
    //         this.polylines.delete(id);
    //     }
    // }

    addPath(paths, id) {
        const line = new google.maps.Polyline({
            path: paths,
            geodesic: true,
            strokeColor: '#11345a',
            strokeOpacity: 0,
            visible: true,
            icons: [
                {
                    icon: this.lineSymbol,
                    offset: "0",
                    repeat: "20px",
                },
            ],
        });

        line.setMap(this.markersElem.googleMap);
        // // setTimeout(() => line.setMap(null), 2000)
        // this.polylinesMain.push(paths)
        this.polylines.set(id, line);
    }

    dragToLocation(markers, geolocation: { lat: any, lng: any }) {
        if (geolocation.lat != undefined && geolocation.lng != undefined) {
            const latLng = new google.maps.LatLng(geolocation.lat, geolocation.lng);
            this.markersElem.googleMap.panTo(latLng);

            this.markersOriginal = [];

            for (let mark of markers) {
                let marker = this.prepareMarker(mark);
                this.markersOriginal.push(marker);
            }

            this.adjustPoints();
            this.unTrackMarkers(['investigator', 'investigator-group']);
            this.markers = [];
            let markersOriginal = JSON.parse(JSON.stringify(this.markersOriginalAdjusted));

            this.markers = markersOriginal;

            this.markersElem.googleMap.setZoom(16);
        }
    }

    updateAndDragMarkers(newMarkers = []) {
        for (let mark of newMarkers) {
            let marker = this.prepareMarker(mark);
            this.updateOriginalMarker(marker);
        }

        this.adjustPoints();
        this.unTrackMarkers(['investigator', 'investigator-group']);
        this.markers = [];
        let markersOriginal = JSON.parse(JSON.stringify(this.markersOriginalAdjusted));

        this.markers = markersOriginal;

        this.markersElem.googleMap.setZoom(16);
    }
    searchByText(text) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: text }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    let lat = results[0].geometry.location.lat();
                    let lng = results[0].geometry.location.lng();
                    this.center = {
                        lat: lat,
                        lng: lng
                    }
                    this.mapSelect.emit({ lat: lat, lng: lng });
                    // console.log('searchByText:', { lat: lat, lng: lng });
                    // You can now use the address data as needed.
                } else {
                    console.error('No address found');
                }
            } else {
                console.error('Geocoder failed due to:', status);
            }
        });
    }
}