import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";
import { RequestService } from "src/app/shared";
import { environment } from "src/environments/environment";

interface Profile {
    _id: string;
    name: string;
    // badgeNo: string;
    // rank: string;
    // division: string;
    pictureLink: string;
    groupMetadata: any;
    investigators: any;
}

@Component({
    selector: 'app-view-investigator-group-dialog',
    templateUrl: './view-investigator-group-dialog.component.html',
    styleUrls: ['./view-investigator-group-dialog.component.scss'],
})

export class ViewInvestigatorGroupDialogComponent implements OnInit, OnDestroy {

    protected subscriptions: Subscription[] = [];
    // public rankEnum: any = [];
    // public divisionEnum: any = [];
    // public badgeNoMeta = undefined;
    // public rankMeta = undefined;
    // public divisionMeta = undefined;
    public investigatorsMeta = undefined;
    public isInvestigator: boolean = false;
    public rolesCannotView: string[] = [environment.customKeys.roleAdmin, environment.customKeys.roleRegionCommanders, environment.customKeys.rolePrefectureCommanders];

    constructor(private requestService: RequestService, public dialogRef: MatDialogRef<ViewInvestigatorGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Profile, private router: Router) {
    }

    ngOnInit(): void {
        // this.rankEnum = this.data.groupMetadata.fields.find(i => i.name == 'rank').enum;
        // this.divisionEnum = this.data.groupMetadata.fields.find(i => i.name == 'division').enum;
        // this.badgeNoMeta = this.data.groupMetadata.fields.find(i => i.name == 'badge_no');
        // this.rankMeta = this.data.groupMetadata.fields.find(i => i.name == 'rank');
        // this.divisionMeta = this.data.groupMetadata.fields.find(i => i.name == 'division');
        this.investigatorsMeta = this.data.groupMetadata.fields.find(i => i.name == 'investigators');
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.isInvestigator = this.requestService.isUserRoleInvestigator();
                }
            })
        );
    }

    ngOnDestroy(): void {

    }

    // viewProfile() {
    //     if (this.data._id) {
    //         this.dialogRef.close();
    //         window.open(`/investigator/${this.data._id}`, "_blank")
    //         // this.router.navigate([`/investigator/${this.data._id}`]);
    //     }
    // }
}